.skills{
	margin-top: 30px;
}

h1.columnTitle{
	font-size: 32px;
	text-align: left;

	height: 100px;
}

li.listItem{
	margin-top: 5px;
	font-size: 16px;
	text-align: left;
	margin-left: 50px;
}

.arrow{
	margin-right: 20px;
}

.skillList{
	list-style: none;
}

@media screen and (max-width: 820px) {
	.skills{
		margin-top: 50px;
	}

	h1.columnTitle{
		height: 75px;
		margin-top: 25px;
	}
}