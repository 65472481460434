*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins',sans-serif;
  text-decoration: none;
  color: white;
}

footer{
  display: block;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
}

footer p{
  text-align: center;
  width: 75%;
}


@media screen and (max-width: 820px) {
  footer{
    margin-top: 40px;
  }
}