.presentation{
	margin-left:0px;

	vertical-align: middle;
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
}

.presentation .presentationContent{
	width: 85%;
}

.presentation .presentationContent .writer{
	font-size: 76px;
}

.presentation .presentationContent #subtitle{
	font-size: 38px;
}

@media screen and (max-width: 820px) {

	.presentation .presentationContent .writer{
		font-size: 50px;
	}

	.presentation .presentationContent #subtitle{
		font-size: 25px;
	}



}