html, body {
  width: 100%;
  height: 100%;

  box-sizing: border-box;
  margin:0;
  padding:0;
  font-family: 'Montserrat', sans-serif;
  background: rgba(14, 25, 45);
}

body {
  margin: 0;
}

.my_fullscreen_div {
  width: 100vw;
  height: 100vh;
}

.App {
  /*text-align: center;*/
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

h1.sectionTitle{
  font-weight: bolder;
}